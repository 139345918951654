
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD06cBf9QI_RgwxctMxni9h63wd6qh3QzU",
  authDomain: "personal-portfolio-parkercodes.firebaseapp.com",
  projectId: "personal-portfolio-parkercodes",
  storageBucket: "personal-portfolio-parkercodes.appspot.com",
  messagingSenderId: "452741867380",
  appId: "1:452741867380:web:dd0cde9a4d1e3e6d0dae21",
  measurementId: "G-YH0FT6L6S7"
};

export default firebaseConfig;